
/* IMPORTAÇÕES */

import TemplateFunctions    from './modules/Template.js';
import LoadMoreFuncton      from './modules/LoadMore.js';
import SearchFuncton        from './modules/Search.js';
import HomeFunction         from './modules/Home.js';
import DetalhesFunction     from './modules/Detalhes.js';
import ValidaLogin          from './modules/Login.js';
import CadastroProf         from './modules/CadastroProfissionais.js';
import CadastroCli          from './modules/CadastroCliente.js';
import UserProfile          from './modules/Perfil.js';
import initLightboxHC       from './modules/LightboxHC.js';
import GoogleMaps           from './modules/GMaps.js';

/* INSTANCIA CLASSES */

const Template      = new TemplateFunctions;
const Home          = new HomeFunction;
const LoadMore      = new LoadMoreFuncton;
const Search        = new SearchFuncton;
const Detalhes      = new DetalhesFunction;
const Login         = new ValidaLogin;
const CadProf       = new CadastroProf;
const CadCli        = new CadastroCli;    
const Perfil        = new UserProfile;
const LightboxHC    = new initLightboxHC;
const GMaps         = new GoogleMaps;

/* RUN EVENTOS HABILITANDO JQUERY */

$(function()
{
    if ($("body.current-page-home").length > 0)
    {
        Home.init();
    }

    if ($("section#profile").length > 0)
    {
        Detalhes.init();
    }

    if ($('body.current-page-login').length > 0 || $('body.current-page-esqueci-minha-senha').length > 0)
    {
        Login.init();
    }

    if ($('body.current-page-cadastrar').length > 0)
    {
        CadCli.init();
    }

    if ($('body.current-page-minha-conta-cliente').length > 0)
    {
        CadCli.init();
    }

    if ($('body.current-page-cadastro-profissional').length > 0)
    {
        CadProf.init();
    }

    if ($('body.current-page-minha-conta-profissional').length > 0 || $('body.current-page-minha-conta-instituto').length > 0)
    {
        CadProf.init();
    }

    if ($('body.current-page-perfil').length > 0)
    {
        Perfil.init();
    }

    if ($('body.current-page-rede-indicacao').length > 0)
    {
        Perfil.init();
    }

    if ($('body.current-page-rede-anuncios').length > 0)
    {
        Perfil.init();
    }

    Template.init();
    Search.init();
    LightboxHC.init();

    if ($('input#ownPlaces').length > 0)
    {
        GMaps.init();
    }
});